import {atom} from 'recoil';
import {recoilPersist} from "recoil-persist";

const { persistAtom } = recoilPersist({
    key: "recoil-persist",
    storage: sessionStorage
});

//    storage: localStorage

const loginState = atom({
    key: 'login',
    default: {
	state:false,
	name:'',
	error:false,
	disk_id:'',
	number:'',
	disk:{},
	menu:true
    },
    effects_UNSTABLE: [persistAtom]
});
export default loginState;
