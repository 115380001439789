import styles from './styles.module.scss';
import { useRecoilState, useResetRecoilState  } from 'recoil';
import { Link, useNavigate } from 'react-router-dom';
import loginState from '../../../states/login';

const Titlelist = (props) => {
    const navigate = useNavigate();
    const [login,setLogin] = useRecoilState(loginState);    
    const reset = useResetRecoilState(loginState);

    const onClick = async (chapter) => {
	await setLogin({...login,number:chapter});
	await navigate('/' + login.disk.path + '/' + chapter);
    };
    const onLogout = async () => {
	if( window.confirm('ログアウトしますか？') ){
	    reset();
	    await navigate('/');
	}
    };

    return(
	    <div className={styles.title_list}>
		<ul className={styles.titles}>
		    {login.disk.list.map((tmp,i)=>{
			let className = '';
			if( parseInt(login.number)===parseInt(tmp.chapter) ){
			    className=styles.on;
			}
			return(
				<li className={className} key={'li' + i} onClick={(e)=>onClick(tmp.chapter)}>
				<span className={styles.chapter}>{tmp.chapter}</span>
				{tmp.title}
				<span className={styles.time}>{tmp.time}</span>
				<span className={styles.size}>{tmp.size} MB</span>
				</li>
			);
		    })}
	            <li className={styles.change} onClick={(e)=>navigate('/')}>ディスク交換</li>
	        </ul>

	    <button onClick={(e)=>onLogout()}>ログアウト</button>
	    </div>
    );
};
export default Titlelist;
