//import FacebookLogin from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import {useRecoilState} from 'recoil';
import { useNavigate } from 'react-router-dom';
import loginState from '../../../../states/login';
import styles from './styles.module.scss';

const Facebook = () => {
    const [login,setLogin] = useRecoilState(loginState);
    const navigate = useNavigate();

    const responseFacebook = async (result) => {
        fetch('https://manage-api.realbaka.net/mrchildren/check-token/' + result.accessToken, {method: 'GET'})
            .then(res => res.json())
            .then(async(data) => {
		if( data.result>0 ){
		    setLogin({
			...login,
			state:true,
			name:result.name,
			error:false
		    });
		}else{
		    setLogin({
			...login,
			state:false,
			name:'',
			error:true
		    });
		}
		navigate('/');
            })
    };

    return(
	    <FacebookLogin appId="1433181917123313" autoLoad={false} disableMobileRedirect={true} redirectUri={'https://mc.tests.jp'} fields="name" callback={responseFacebook} render={renderProps => (
	    	    <div className={styles.container}>
		    <div>
	    	    <img src="/images/icon.jpg" onClick={renderProps.onClick} alt="Mr.Children" />
		    {login.error&&
		     <p>ログインには認証が必要だよ。中西に連絡してね。</p>
		    }
		    </div>
		    </div>)}
	    />
    );
};
export default Facebook;
