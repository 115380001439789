import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from 'recoil';
import { QueryClient, QueryClientProvider } from 'react-query';
//import { ReactQueryDevtools } from 'react-query/devtools';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
	retry: false,
	refetchOnWindowFocus: true,
	staleTime: 1000 * 5,
	cacheTime: 1000 * 5,
	/*refetchInterval: 10 * 1000*/
    },
  }
});

const touchHandler = (event) => {
    if (event.touches.length > 1) {
	event.preventDefault();
    }
};
document.addEventListener('touchstart', touchHandler, {
    passive: false
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
	<BrowserRouter>
	    <QueryClientProvider client={queryClient}>
		<RecoilRoot>
		    <App />
		</RecoilRoot>
	    </QueryClientProvider>
	</BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
