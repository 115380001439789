import React,{useState} from "react";
import { useRecoilState } from "recoil";
import styles   from './styles.module.scss';
import modal from './modal.module.scss';

import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { initQuery, initConfig } from '../../queries/init';

import loginState from '../../states/login';
import Type from '../ui/type';

function Main() {
    const [login,setLogin] = useRecoilState(loginState);
    const [submodal,setSubModal] = useState(false);
    const navigate = useNavigate();

    const queryInfo = useQuery(["initQuery"],initQuery,initConfig);
    if( queryInfo.isLoading ){
	return 'Loading...';
    }
    if( queryInfo.error ){
	return 'An error has occurred: ' + queryInfo.error.message;
    }

    const selectDisk = async (i) => {
	const disk = queryInfo.data[i];
	await setLogin({...login,number:disk.first,disk:disk});
	await navigate('/'+disk.path+'/'+disk.first);
    }

    const openModal = async (i) => {
	setSubModal(i);
    }    
    
    return(
	<>
	<div className={modal.container}>
	     <h1>ディスク選択</h1>
	     <table className={styles.alldisk}>
		 <thead>
		     <tr>
		     <th>種別</th>
		     <th>発売日</th>
		     <th>タイトル</th>
		     </tr>
		 </thead>
		 <tbody>
		     {queryInfo.data.map((tmp,i)=>{
			 let className="";
			 if( tmp.status===0 ){
			     className=styles.ng;
			 }

			 if( tmp.status===1 ){
			     return(
				 <tr key={'disk_tr' + i} className={className} onClick={(e)=>openModal(i)}>
				     <td><Type type={tmp.type}/></td>
				     <td>{tmp.release_date}</td>
				     <td>{tmp.title}</td>
				 </tr>
			     );
			 }else{
			     return(
				 <tr key={'disk_tr' + i} className={className}>
				     <td><Type type={tmp.type}/></td>
				     <td>{tmp.release_date}</td>
				     <td>{tmp.title}</td>
				 </tr>
			     );
			 }
		     })}
		 </tbody>
	     </table>
        </div>
	    {submodal&&
	    <div className={modal.info}>
		<div>
		    <div>
			<img src={"/images/cover/" + queryInfo.data[submodal].path + ".jpg"} alt="cover" /><br />
			<span>{queryInfo.data[submodal].title}</span><br />
			{queryInfo.data[submodal].sub_title}<br />
			{queryInfo.data[submodal].description}<br />
		    </div>
		    <p><input type="button" value="このディスクを選択" name="select" onClick={(e)=>selectDisk(submodal)} /></p>
		    <p><input type="button" value="キャンセル" onClick={(e)=>setSubModal(false)} /></p>
		</div>
	    </div>
	    }
	</>
    );
}

export default Main;
