import styles from './styles.module.scss';
import { useRecoilState } from 'recoil';
import loginState from '../../../../states/login';

const MenuButton = (props) => {
    let css = styles.container;
    const [login,setLogin] = useRecoilState(loginState);

    if( !login.menu ){
	css = styles.container_close;
    }

    const onClick = () => {
	setLogin({...login,menu:!login.menu});
    };
    
    return(
	<>
	    {props.horizontal&&
	     <span className={css} onClick={(e)=>onClick()}><img src="/images/arrow.png" alt="icon" /></span>
	    }
	</>
    );
};
export default MenuButton;
