import styles from './styles.module.scss';
import Type from '../type';
import { useNavigate } from 'react-router-dom';

const Diskinfo = (props) => {
    const disk = props.disk;
    const navigate = useNavigate();
    
    return(
	<div className={styles.container}>
	    <div>
		<img src={"/images/cover/" + disk.path + '.jpg'} alt={disk.title} onClick={(e)=>navigate('/')} />
	    </div>
	    <div>
		<p className={styles.release_date}><Type type={disk.type} />RELEASE: {disk.release_date}</p>
		{disk.title}
		{disk.description}
	    </div>
	</div>		
    );
};
export default Diskinfo;
