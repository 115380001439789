import React from "react";
import { useRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';
import { useWindowDimensions } from './window_dimensions';
import { useNavigate } from 'react-router-dom';

import Diskinfo from '../ui/diskinfo';
import Titlelist from '../ui/titlelist';
import MenuButton from '../ui/buttons/menu';

import loginState from '../../states/login';

import styles   from './styles.module.scss';
import vertical from './vertical.module.scss';
import close from './close.module.scss';

const Player = (props) => {
    const params = useParams();
    const { width, height } = useWindowDimensions();
    const navigate = useNavigate();
    const [login,setLogin] = useRecoilState(loginState);

    let css = styles;
    let horizontal = true;
    if( height>width ){
	css = vertical;
	horizontal = false;
    }else{
	if( !login.menu ){
	    css = close;
	}
    }
    
    const handleClick = async (number) => {
	navigate('/');
    };

    const onEnded = async() => {
	if( login.number < login.disk.last ){
	    let new_number = login.number + 1;
	    await setLogin({...login,number:new_number});
	    navigate('/' + login.disk.path + '/' + new_number);
	}
    }


    let title='';
    for(var i=0;i<login.disk.list.length;i++){
	if( parseInt(login.number)===parseInt(login.disk.list[i].chapter) ){
	    title = login.disk.list[i].title;
	    break;
	}
    }
    
    
    const src = 'https://s3.ap-northeast-1.wasabisys.com/mr.children/' + params.disk + '/' + params.number + '.mp4';
    return(
	<div className={css.container}>
	    <div className={css.main}>
		<div className={css.box}>
		    <div className={css.title}>{title}<span>{login.number} / {login.disk.list[login.disk.list.length-1].chapter}</span></div>
		    <div className={css.video}>
			<React.Fragment key={src}>
			    <video controls className={css.video} autoPlay playsInline onEnded={onEnded}>
				<source src={src} type="video/mp4" />
				<p>Your browser doesn't support HTML5 video.</p>
			    </video>
			</React.Fragment>
		    </div>
		    <div className={css.title}></div>
		</div>
	    </div>
	    {horizontal&&
	     <div className={styles.menu}>
		 <Diskinfo disk={login.disk}/>
		 <Titlelist list={[]} number={login.number} handleClick={handleClick} />
	     </div>
	    }
	    {!horizontal&&
	     <>
		 <Diskinfo disk={login.disk}/>
		 <Titlelist list={[]} number={login.number} handleClick={handleClick} />
	     </>
	    }
	    <MenuButton horizontal={horizontal} />
	</div>
    );
};
export default Player;
