import './global.module.scss';

import {useRecoilValue} from 'recoil';
import { Routes, Route, Link } from "react-router-dom";

import loginState from './states/login';

import Facebook from './components/ui/buttons/facebook';
import Main from './components/main';
import Player from './components/player';

function App() {
    const login = useRecoilValue(loginState);

    if( !login.state ){
	return(<Facebook />);
    }

    return(
	    <Routes>
            <Route path="/" element={<Main />} />
            <Route path=":disk/:number" element={<Player />} />
	    </Routes>
    );
}

export default App;
