import styles from './styles.module.scss';

const Type = (props) => {

    const typelist = {
	'ld':'LD',
	'vhs':'VHS',
	'e-cd':'Enhanced CD',
	'cd':'CD',
	'dvd':'DVD',
		'blu-ray':'BLU-RAY',
		'streaming':'配信'
    };

    const color = {
	'ld':'#088',
	'vhs':'orange',
	'e-cd':'#800',
	'cd':'#800',
	'dvd':'#008',
		'blu-ray':'#080',
		'streaming':'#f00'
    }
    
    return(
	    <span className={styles.container} style={{'background':color[props.type]}}>{typelist[props.type]}</span>
    );
};
export default Type;
